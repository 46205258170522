import "styles/globals.css";
import { CacheProvider, EmotionCache } from "@emotion/react";
import { useEffect, useState, FunctionComponent, useMemo } from "react";
import { useRouter } from "next/router";
import type { AppProps } from "next/app";
import CssBaseline from "@mui/material/CssBaseline";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import createEmotionCache from "utility/createEmotionCache";

import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import ErrorBoundary from "components/ErrorBoundary.js";

import { AppStateProvider } from "components/mainPage/state/state";
import { UserProvider } from "@auth0/nextjs-auth0/client";
import Head from "next/head";
import MuiXLicense from "MuiXLicense";

import { SpeedInsights } from "@vercel/speed-insights/next";
import { Analytics } from "@vercel/analytics/react";

import DatadogInit from "datadog-init";

import { LocalStorageProvider } from "../contexts/LocalStorageContext";
import GlobalCssPriority from "../components/GlobalCssPriority";
// Set the default timezone to UTC
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import customParseFormat from "dayjs/plugin/customParseFormat";
import IntercomClientComponent from "components/IntercomClient/IntercomClient";
import { SnackbarProvider } from "contexts/SnackbarContext";
dayjs.extend(customParseFormat);
dayjs.extend(utc);

interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache;
}

const MyApp: FunctionComponent<MyAppProps> = (props) => {
  const [queryClient] = useState(() => new QueryClient());
  const [nonce, setNonce] = useState("");
  const clientSideEmotionCache = useMemo(
    () => createEmotionCache(nonce),
    [nonce]
  );
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;
  const router = useRouter();
  const localStorage = () => {
    if (typeof window !== "undefined") {
      return window.localStorage;
    }
    return null;
  };

  const userId = localStorage()?.userId;

  useEffect(() => {
    // Get the nonce from the headers set in middleware
    setNonce(
      document.head
        .querySelector('meta[property="csp-nonce"]')
        ?.getAttribute("content") || ""
    );
  }, []);

  return (
    <UserProvider>
      <QueryClientProvider client={queryClient}>
        <AppStateProvider>
          <CacheProvider value={emotionCache || clientSideEmotionCache}>
            <GlobalCssPriority>
              <CssBaseline />
              <Head>
                <meta property="csp-nonce" content={nonce} />
                <meta
                  name="viewport"
                  content="width=device-width, initial-scale=1"
                />
                <title>SocialCrowd</title>
              </Head>
              <IntercomClientComponent userId={userId} />
              <Analytics />
              <SpeedInsights />
              <DatadogInit />
              <LocalStorageProvider>
                <ErrorBoundary>
                  <MuiXLicense />
                  <SnackbarProvider>
                    <Component
                      {...pageProps}
                      nonce={nonce}
                      key={!router.isReady && router.asPath}
                    />
                  </SnackbarProvider>
                </ErrorBoundary>
              </LocalStorageProvider>
            </GlobalCssPriority>
          </CacheProvider>
        </AppStateProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </UserProvider>
  );
};

export default MyApp;
